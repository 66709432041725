<template>
  <div>
    <datatable-heading
      :title="$t('menu.applications')"
      :changePageSize="changePageSize"
      :searchChange="_.debounce(searchChange, 500)"
      :from="from"
      :to="to"
      :total="total"
      :perPage="perPage"
      :tableFields="fields"
      @filterChange="filterChange"
    >
    </datatable-heading>
    <b-overlay :show="gridLoading" variant="transparent" blur="5px" opacity="1">
      <b-row>
        <b-colxx xxs="12">
          <b-card>
            <vuetable
              ref="vuetable"
              track-by="applicationID"
              :api-url="apiBase"
              :query-params="makeQueryParams"
              :per-page="perPage"
              :reactive-api-url="false"
              :fields="fields"
              :css="css.table"
              pagination-path
              :row-class="onRowClass"
              @vuetable:pagination-data="onPaginationData"
              @vuetable:row-clicked="rowClicked"
              @vuetable:cell-rightclicked="rightClicked"
              @vuetable:load-error="handleLoadError"
              @vuetable:cell-clicked="cellclicked"
              @vuetable:loading="onVuetableLoading"
              @vuetable:loaded="onVuetableLoaded"
            >
            </vuetable>
          </b-card>
          <vuetable-pagination-bootstrap
            class="mt-4"
            ref="pagination"
            @vuetable-pagination:change-page="onChangePage"
          />
        </b-colxx>
      </b-row>

      <v-contextmenu ref="contextmenu">
      <v-contextmenu-item @click="onContextMenuAction('pdf')">
        <i :class="`iconsminds-${processing ? 'refresh' : 'download-1'}`" />
        <span>View as PDF</span>
      </v-contextmenu-item>
    </v-contextmenu>
    </b-overlay>
  </div>
</template>
<script>
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationBootstrap from '../../../../components/Common/VuetablePaginationBootstrap'
import { BeaconapiUrl } from '../../../../constants/config'
import DatatableHeading from '../../../../containers/datatable/DatatableHeading'
import CssTableConfig from '../../../../constants/VuetableBootstrapconfig'
import { mapMutations } from 'vuex'
import moment from 'moment/moment.js'
import ApplicationMixins from '../../../../mixins/ApplicationMixins.vue'
export default {
  props: {
    applicationID: {
      type: Number,
      default: 1
    }
  },
  mixins: [ApplicationMixins],
  components: {
    Vuetable,
    'vuetable-pagination-bootstrap': VuetablePaginationBootstrap,
    'datatable-heading': DatatableHeading
  },
  data () {
    var _idfield = 'applicationID'
    return {
      isLoad: false,
      apiBase: `${BeaconapiUrl}/api/application/application-table`,
      sort: '',
      page: 1,
      perPage: 50,
      search: '',
      filters: [],
      from: 0,
      to: 0,
      total: 0,
      lastPage: 0,
      items: [],
      selectedItems: [],
      idfield: _idfield,
      css: CssTableConfig,
      gridLoading: false,
      fields: [
        {
          name: _idfield,
          sortField: _idfield,
          title: 'Application ID',
          titleClass: '',
          dataClass: 'text-muted',
          width: '12%',
          filterType: 'number'
        },
        {
          name: 'companyID',
          sortField: 'companyID',
          title: 'Company ID',
          titleClass: '',
          dataClass: 'text-muted',
          width: '12%',
          filterType: 'text'
        },
        {
          name: 'dateSubmitted',
          sortField: 'dateSubmitted',
          title: 'Date Submitted',
          titleClass: '',
          dataClass: 'text-muted',
          width: '12%',
          filterType: 'text',
          callback: this.formatDate
        },
        {
          name: 'type',
          sortField: 'type',
          title: 'Type',
          titleClass: '',
          dataClass: 'text-muted',
          width: '12%',
          filterType: 'text'
        },
        {
          name: 'status',
          sortField: 'status',
          title: 'Status',
          titleClass: 'text-muted',
          dataClass: 'text-muted',
          width: '12%',
          filterType: 'text'
        },
        {
          name: 'advisor',
          sortField: 'advisor',
          title: 'Advisor',
          titleClass: '',
          dataClass: 'text-muted',
          width: '12%',
          filterType: 'text'
        },
        {
          name: 'salesMember',
          sortField: 'salesMember',
          title: 'Sales Member',
          titleClass: '',
          dataClass: 'text-muted',
          width: '12%',
          filterType: 'text'
        },
        {
          name: 'quoteAmount',
          sortField: 'quoteAmount',
          title: 'Initial Quote',
          titleClass: '',
          dataClass: 'text-muted',
          width: '12%',
          filterType: 'number',
          callback: this.formatMoney
        }
      ],
      json_data: []
    }
  },
  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setAlertMessage',
      'setInfoMessage',
      'appendInfoMessage'
    ]),
    makeQueryParams (sortOrder, currentPage, perPage) {
      this.selectedItems = []
      return sortOrder[0]
        ? {
          sort: sortOrder[0]
            ? sortOrder[0].field + '|' + sortOrder[0].direction
            : '',
          page: currentPage,
          per_page: this.perPage,
          search: this.search,
          filters: JSON.stringify(this.filters)
        }
        : {
          page: currentPage,
          per_page: this.perPage,
          search: this.search,
          filters: JSON.stringify(this.filters)
        }
    },
    onVuetableLoading () {
      this.gridLoading = true
    },
    onVuetableLoaded () {
      this.gridLoading = false
    },
    onPaginationData (paginationData) {
      this.from = paginationData.from
      this.to = paginationData.to
      this.total = paginationData.total
      this.lastPage = paginationData.last_page
      this.items = paginationData.data
      this.$refs.pagination.setPaginationData(paginationData)
      this.json_data = paginationData.data
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    changePageSize (perPage) {
      this.perPage = perPage
      this.refreshVueTable()
    },
    searchChange (val) {
      this.search = val
      this.refreshVueTable()
    },
    filterChange (filters) {
      this.filters = filters
      this.refreshVueTable()
    },
    handleLoadError (response) {
      this.setDangerMessage(response)
    },
    onRowClass (dataItem, index) {
      var outputclass = ''
      if (this.selectedItems.includes(dataItem[this.$data.idfield])) {
        outputclass = 'selected'
      }
      return outputclass
    },
    async onContextMenuAction (action) {
      var rightClickedItem = this.rightClickedItem
      var applicationID = rightClickedItem.applicationID

      switch (action) {
        case 'pdf':
        {
          await this.getApplicationPdf(applicationID)
          break
        }
        default:
          break
      }
    },
    cellclicked (dataitem, field, event) {},
    rightClicked (dataItem, field, event) {
      event.preventDefault()
      this.rightClickedItem = dataItem
      const itemId = dataItem[this.$data.idfield]
      if (!this.$refs.vuetable.selectedTo.includes(itemId)) {
        this.$refs.vuetable.selectedTo = [itemId]
      }
      this.$refs.contextmenu.show({ top: event.pageY, left: event.pageX })
      this.selectedItems = this.$refs.vuetable.selectedTo
    },
    rowClicked () {

    },
    refreshVueTable () {
      this.$refs.vuetable.refresh()
    },
    formatMoney (value) {
      if (value === '' || value === 0) {
        return ''
      } else {
        return value.toLocaleString('en-US',
          {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
          })
      }
    },
    formatDate (value) {
      if (value === null) {
        return ''
      } else {
        return moment(value, 'YYYY-MM-DD hh:mm A').format('MM/DD/YYYY hh:mm A')
      }
    }
  }
}
</script>
